<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <div class="d-flex justify-content-around align-items-center">

          <div>
            <img src="../../assets/images/logo/logo-black.png" alt="" width="200">
          </div>
        </div>
        <b-card-text class="mb-2 mt-3">
          Ingresa tu PIN
        </b-card-text>

        <!-- form -->
        <validation-observer
            ref="loginForm"
            #default="{invalid}"
        >
          <b-form
              class="auth-login-form mt-2"
              @submit.prevent="SignIn"
          >
            <!-- email -->
            <b-form-group
                label-for="pin"
                label="PIN"
            >

              <b-form-input
                  id="pin"
                  v-model="digits"
                  name="login-pin"
                  placeholder="1234"
                  autofocus
                  type="password"
              />

            </b-form-group>

            <!-- submit button -->
            <b-button
                variant="primary mt-2 mb-1"
                type="submit"
                block
                :disabled="invalid"
            >
              Iniciar sesión
            </b-button>
          </b-form>
        </validation-observer>

      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {required, pin} from '@validations'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import {mapActions, mapGetters} from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  mixins: [togglePasswordVisibility],
  data() {
    return {
      digits: '',
      required: required,
      pin,
      error: false
    }
  },

  methods: {
    ...mapActions('auth', ['signin']),


    async SignIn() {
      if (!this.digits) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Campo pin requerido",
            icon: "error",
            variant: "warning",
          },
        });
        return
      }

      const send = {
        pin: this.digits,
      }
      const response = await this.signin(send);
      if (response.user) {
        await this.$router.push('/')

      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "error",
            variant: "warning",
          },
        });
      }

    }
  },
  computed: {},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
